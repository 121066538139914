import { Injectable, Inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';

@Injectable()
export class StylesSheetsInjectorService {
    constructor(private router: Router,
                private route:ActivatedRoute,
                @Inject(DOCUMENT) private document) { }

    public handleCSSfileInjection(): void {
        // Instead of dynamically injecting CSS files skipping angular web packprocess, unload unnecessary CSS files
        // Eventual checks should be implemented to skip unloading needed global CSS files
        if ( this.router.url.indexOf('/inspections') > -1) {
          [].forEach.call(document.querySelectorAll('head style[type="text/css"]'), element => {
            element.remove();
            
          });
          console.log('Boilerplate files unloaded for inspection section.')
        }
    }
}
